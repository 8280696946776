import { fromJS } from 'immutable';

// action type 用來分辨現在的Action是哪個Action
export const SET_INDEX = 'App/ResultPage/SET_INDEX';
export const FORM_STORED = 'App/ResultPage/FORM_STORED';
export const QUESTION_SUBMMITED = 'App/ResultPage/QUESTION_SUBMMITED';

export const formStored = () => ({
  type: FORM_STORED,
});

export const setIndex = (index) => ({
  type: SET_INDEX,
  index,
});

export const questionSubmitted = (rowId) => ({
  type: QUESTION_SUBMMITED,
  rowId,
});

const initialState = fromJS({
  rowId: null,
  formIsStored: false,
  index: 0,
});

export default function QuestionReducer(state = initialState, action) {
  switch (action.type) {
    case FORM_STORED:
      return state.set('formIsStored', true);
    case QUESTION_SUBMMITED:
      return state.set('rowId', action.rowId);
    case SET_INDEX:
      return state.set('index', action.index);
    default:
      return state;
  }
}
