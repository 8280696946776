import { createGlobalStyle } from 'styled-components';

import theme from './theme';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Noto+Sans+TC:400,700&subset=chinese-traditional');
  body {
    font-family: ${theme.font};
    min-width: 100%;
    min-height: 100%;
  }
`;
