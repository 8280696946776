import { connect } from 'react-redux';
import { compose } from 'redux';
import camelCase from 'lodash/camelCase';

import injectReducer from '../../utils/injectReducer';

export default ({
  key,
  actions,
  selectors,
  reducer,
}, request) => (SubComp) => {
  const mapStateToProps = (state) => ({
    [`${key}Loading`]: selectors.makeSelectLoading(state),
    [`${key}Response`]: selectors.makeSelectResponse(state),
    [`${key}Error`]: selectors.makeSelectError(state),
  });

  const mapDispatchToProps = (dispatch) => ({
    [camelCase(`request-${key}`)]: (payload) => {
      dispatch(actions.sendRequest(payload));
      return request(payload)
        .then((res) => {
          dispatch(actions.responseSuccess(res));
          return res;
        })
        .catch((e) => {
          dispatch(actions.responseError(e));
          throw e;
        });
    },
  });

  return compose(
    injectReducer({ key, reducer }),
    connect(mapStateToProps, mapDispatchToProps)
  )(SubComp);
};
