/**
 * Create the store with dynamic reducers
 */

import { createStore, compose, applyMiddleware } from 'redux';
import { fromJS } from 'immutable';
import { createResponsiveStoreEnhancer } from 'redux-responsive';
import createSagaMiddleware from 'redux-saga';

import createReducer from './reducers';
import resultSaga from '../containers/Result/saga'

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState = {}) {
  const enhancers = [
    applyMiddleware(sagaMiddleware),
    createResponsiveStoreEnhancer({ calculateInitialState: false }),
  ];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    (process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  /* eslint-enable */

  const store = createStore(
    createReducer(),
    fromJS(initialState),
    composeEnhancers(...enhancers)
  );

  // Extensions
  store.runSaga = sagaMiddleware.run;
  sagaMiddleware.run(resultSaga);
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  return store;
}
