import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';

const initialState = fromJS({
  loading: false,
  response: null,
  error: null,
});

export default (key) => {
  const SEND_REQUEST = `App/Request/${key}/SEND_REQUEST`;
  const RESPONSE_SUCCESSS = `App/Request/${key}/RESPONSE_SUCCESSS`;
  const RESPONSE_ERROR = `App/Request/${key}/RESPONSE_ERROR`;

  const sendRequest = createAction(SEND_REQUEST);
  const responseSuccess = createAction(RESPONSE_SUCCESSS);
  const responseError = createAction(RESPONSE_ERROR);

  const reducer = handleActions({
    [sendRequest]: (state) => state.set('loading', true),
    [responseSuccess]: (state, { payload }) => state
      .set('loading', false)
      .set('error', null)
      .set('response', payload),
    [responseError]: (state, { payload }) => state
      .set('loading', false)
      .set('error', payload),
  }, initialState);

  const makeSelectLoading = (state) => state.getIn([key, 'loading']);
  const makeSelectResponse = (state) => state.getIn([key, 'response']);
  const makeSelectError = (state) => state.getIn([key, 'error']);

  return {
    key,
    constants: {
      SEND_REQUEST,
      RESPONSE_SUCCESSS,
      RESPONSE_ERROR,
    },
    actions: {
      sendRequest,
      responseSuccess,
      responseError,
    },
    selectors: {
      makeSelectLoading,
      makeSelectResponse,
      makeSelectError,
    },
    reducer,
  };
};
