import merge from 'lodash/merge';
import range from 'lodash/range';

const emToPx = (em) => `${em * 16}px`;

export const breakpoints = [22, 36, 48, 62, 90, 120].map(emToPx);
export const containerWidth = [22, 36, 46, 58].map(emToPx);
export const mobileOrDesktop = (mobile, desktop, se, laptop) => [se || mobile, mobile, null, laptop || desktop, null, desktop];

const generateFade = (r, g, b) => range(10, 100, 10)
  .reduce((fade, opacity) => merge(fade, { [opacity]: `rgba(${[r, g, b, opacity / 100].join()})` }), {});

const font = 'Helvatica, Arial, "Noto Sans TC", sans-serif';

const white = '#fff';
const black = '#000';
const yellow = '#F6E105';
const gray = '#F2F2F2';
const darkGray = '#B3B3B3';

export default {
  colors: {
    white,
    black,
    yellow,
    gray,
    darkGray,
    text: black,
    fade: {
      white: generateFade(255, 255, 255),
      black: generateFade(0, 0, 0),
    },
  },
  breakpoints,
  containerWidth,
  font,
  headerHeight: '5em',
  mobileOrDesktop,
  zOrder: range(4).map((i) => 10 ** i),
};
