import generateHoc from '../request/generateRequestHoc';
import generateReducer from '../request/generateRequestReducer';
import config from './config';
import request from '../../utils/request';

export const reducer = generateReducer('formStorage');

export const requestAction = (form) => request(`${config.url}/form`, {
  method: 'POST',
  body: JSON.stringify(form),
  headers: {
    'content-type': 'application/json',
  },
});

export default generateHoc(reducer, requestAction);
