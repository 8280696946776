
import { put, takeLatest, all } from 'redux-saga/effects';
import { reducer as answerReducer } from '../../services/googleSheets/storeAnswers'
import { reducer as formReducer } from '../../services/googleSheets/storeForm'
import { questionSubmitted, formStored } from '../../containers/Result/reducer'

export function* storeRowId({ payload: { data } }) {
  const [, pos] = data.updatedRange.split(':');
  yield put(questionSubmitted(pos.replace(/\D/g, '')))
}

export function* formSubmitted() {
  if (window.ga) {
    window.ga('send', 'event', '問券', '完成填答');
  }
  yield put(formStored())
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* main() {
  yield all([
    takeLatest(answerReducer.constants.RESPONSE_SUCCESSS, storeRowId),
    takeLatest(formReducer.constants.RESPONSE_SUCCESSS, formSubmitted),
  ]);
}
