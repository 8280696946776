module.exports = [{
      plugin: require('C:/Users/yrj19/yllscholarships/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-77055530-25"},
    },{
      plugin: require('C:/Users/yrj19/yllscholarships/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/yrj19/yllscholarships/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
